<template>
  <form class="form-signin row align-items-center" @submit.prevent="sendForm">
    <div class="col">
      <h1 class="h3 mb-3 font-weight-normal">Por favor Identifícate</h1>
      <div class="mb-3">
        <label for="inputEmail" class="sr-only">Email address</label>
        <input class="form-control" v-app-input-focused placeholder="Email" type="email" v-model="email" />
      </div>
      <div class="mb-3">
        <label for="inputPassword" class="sr-only">Password</label>
        <app-input-password class="input-group-alternative" v-model="password"></app-input-password>
      </div>
      <app-button-submit class="btn-block btn-lg" :loading="sending">
        Ingresar
      </app-button-submit>
      <div class="alert mt-3 alert-danger" v-show="errorMessage">
        {{ errorMessage }}
      </div>
      <p class="mt-5 mb-3 text-muted text-monospace">{{ buildName }} © 2024</p>
    </div>
  </form>
</template>
<script>
import {mapState, mapActions} from "vuex";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    /**
     *
     * Accion que debe realizar despues de redireccionar
     *
     */
    afterLogged: {
      type: Function,
      default: () => {},
    },
  },

  data: () => ({
    email: "",
    password: "",
    sending: false,
    errorMessage: "",
  }),

  computed: {
    ...mapState({
      logueado: (s) => s.auth.logged,
    }),
    buildName() {
      return process.env.VUE_APP_NAME + " " + process.env.VUE_APP_VERSION;
    }
  },

  watch: {
    //
  },

  created() {
    //
  },

  mounted() {
    // $(".form-control")
    //   .on("focus blur", function(e) {
    //     $(this)
    //       .parents(".form-group")
    //       .toggleClass("focused", e.type === "focus");
    //   })
    //   .trigger("blur");
  },

  methods: {
    ...mapActions({
      logout: "auth/logout",
      login: "auth/login",
    }),
    async sendForm() {
      this.sending = true;
      try {
        this.errorMessage = "";
        await this.login({
          username: this.email,
          password: this.password,
        });
        this.afterLogged(this);
      } catch (e) {
        if (e.toString !== undefined || typeof (e) === "string") {
          this.errorMessage = e;
        } else if (e.response) {
          this.errorMessage = e.response.data.message;
        } else {
          this.errorMessage = "Algo malio sal :(";
        }
        this.sending = false;
      }
    },
  },
};
</script>
<style>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  height: 100vh;
}
</style>
